@mixin btnIcon($size: --size-tall) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: var($size);
  width: var($size);
  border-radius: 4px;
}

a {
  font-family: var(--font-title), serif;
  font-weight: var(--font-weight-regular);
  //font-size: var(--font-size-regular);
  text-decoration: underline;
  color: var(--color-primary);

  &:hover {
    color: var(--color-primary-lighten);
  }

  &::before {
    text-decoration: none;
  }
}

@mixin loaderBtn() {
  &.is-loading {
    color: transparent !important;

    &:before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid var(--color-white);
      border-top-color: var(--color-primary);
      animation: spinner 0.8s linear infinite;
    }
  }
}

//////////////////////// BUTTON GLOBAL ////////////////////////
//////////////////////// BUTTON GLOBAL ////////////////////////

button,
a {
  box-shadow: none !important;

  &.mat-mdc-raised-button,
  &.mat-mdc-stroked-button,
  &.mat-mdc-outlined-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: var(--size-tall);
    margin: 0;
    //flex: 1;
    font-family: var(--font-title), serif;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-regular);
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: none;
    @media all and (max-width: 576px) {
      font-size: var(--font-size-text);
    }
  }

  &.btn-text-icon {
    width: auto;

    .mdc-button__label {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      overflow-wrap: normal;
    }

  }

  @include loaderBtn();

  &.btn-badge {
    display: inline-flex;
    height: var(--size-mini);
    padding: 0 10px;
    font-family: var(--font-title);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-text);
  }
}


//////////////////////// BUTTON RAISED COLORS ////////////////////////
//////////////////////// BUTTON RAISED COLORS ////////////////////////

@mixin matColor($color: --color-primary) {
  color: var(--color-white);
  &:hover,
  &.active {
    background-color: var($color);
    color: var(--color-white);
  }
}

.mdc-fab,
.mini-fab,
.mat-mdc-raised-button {
  &.mat-primary {
    background-color: var(--color-primary);
    @include matColor(--color-primary-lighten);
  }

  &.mat-accent {
    background-color: var(--color-secondary);
    @include matColor(--color-secondary-lighten);
  }

  &.mat-third {
    background-color: var(--color-third);
    @include matColor(--color-third-lighten);
  }

  &.mat-grey {
    background-color: var(--color-grey-darken);
    @include matColor(--color-grey);
  }

  &.mat-greyPrimary {
    background-color: var(--color-grey-darken);
    @include matColor(--color-primary);
  }

  &.mat-greyAccent {
    background-color: var(--color-grey-darken);
    @include matColor(--color-secondary);
  }

  &.mat-warn {
    background-color: var(--color-danger);
    @include matColor(--color-danger-lighten);
  }

  &.mat-thirdPrimary {
    background-color: var(--color-third);
    @include matColor(--color-primary);
  }

  &.mat-thirdAccent {
    background-color: var(--color-third);
    @include matColor(--color-secondary);
  }

  &.mat-button-disabled {
    background-color: var(--color-grey) !important;
    color: var(--color-third-lighten) !important;
    opacity: 0.5;
  }

  &:hover {
    text-decoration: none;
  }
}

//////////////////////// BUTTON STROKED COLORS ////////////////////////
//////////////////////// BUTTON STROKED COLORS ////////////////////////

@mixin matStrokeColor($color: --color-primary) {
  &:hover,
  &.active {
    border-color: var($color);
    color: var($color);
  }
}

button,
a {
  &.mat-button-base.mat-mdc-stroked-button {
    &.mat-primary {
      border-color: var(--color-primary);
      color: var(--color-primary);
      @include matStrokeColor(--color-primary-lighten);
    }

    &.mat-accent {
      border-color: var(--color-secondary);
      color: var(--color-secondary);
      @include matStrokeColor(--color-secondary-lighten);
    }

    &.mat-third {
      border-color: var(--color-third);
      color: var(--color-tird);
      @include matStrokeColor(--color-third-lighten);
    }

    &.mat-grey {
      border-color: var(--color-grey-darken);
      color: var(--color-grey-darken);
      @include matStrokeColor(--color-secondary);
    }

    &.mat-warn {
      border-color: var(--color-danger);
      color: var(--color-danger);
      @include matStrokeColor(--color-danger-lighten);
    }

    &.mat-thirdPrimary {
      border-color: var(--color-third);
      color: var(--color-tird);
      @include matStrokeColor(--color-primary);
    }

    &.mat-thirdAccent {
      border-color: var(--color-third);
      color: var(--color-tird);
      @include matStrokeColor(--color-secondary);
    }
  }
}

//////////////////////// BUTTON RAISED SIZES ////////////////////////
//////////////////////// BUTTON RAISED SIZES ////////////////////////

button,
a {
  &.mdc-fab {
    @include btnIcon(--size-tall);

  }

  &.mat-mdc-fab.mini-fab {
    @include btnIcon(--size-mini);

    & i {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
    }

  }

  &.btn-medium-size {
    width: var(--size-medium);
    height: var(--size-medium);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

//////////////////////// BUTTON ANIM ////////////////////////
//////////////////////// BUTTON ANIM ////////////////////////

button,
button.mat-mdc-raised-button,
a {
  .icon-refresh::before {
    display: block;
    transform: rotate(0deg);
    transition: ease-in-out 0.5s;
  }

  transition: ease-in-out 0.5s;

  &:hover {
    .icon-refresh::before {
      transform: rotate(45deg);
      transition: ease-in-out 0.5s;
    }
  }
}

button {
  .mdc-fab,
  .mdc-mat-fab.mini-fab {
    @include loaderBtn();
  }
}
