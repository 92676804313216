:root {
  //////////////////// SIZE ///////////////////

  --size-tall: 50px;
  --size-medium: 40px;
  --size-mini: 25px;
  --size-checkbox: 20px;

  --height-header: 100px;
  --height-subheader: 110px;
  --height-footer: 30px;
  --height-footer-bottom: 30px;

  --padding-tall: 50px;
  --padding-medium: 40px;
  --padding-regular: 25px;
  --padding-small: 15px;
  --padding-mini: 10px;

  --margin-tall: 50px;
  --margin-medium: 40px;
  --margin-regular: 25px;
  --margin-small: 15px;
  --margin-mini: 10px;
}
