.mat-stepper-horizontal {
  margin: 0 -20px;
  .mat-horizontal-stepper-header {
    overflow: visible;
    height: --size-medium;
    border-radius: 4px;
    padding: 0 15px;
    margin: 0 5px;
    background: transparent;
  }

  .mat-stepper-horizontal-line {
    margin: 0;
  }

  .mat-horizontal-content-container {
    padding-bottom: 0;
  }

  ///////////////// LABEL /////////////////
  ///////////////// LABEL /////////////////

  .mat-step-header {
    .mat-step-label {
      font-family: var(--font-title);
      font-size: var(--font-size-text);
      color: var(--color-third);
    }
    .mat-step-label-active {
      color: var(--color-primary);
    }
    .mat-step-label-selected {
      color: var(--color-primary);
      font-weight: var(--font-weight-regular);
    }

    &:hover {
      .mat-step-label-active {
        color: var(--color-primary-lighten);
      }
    }

    ///////////////// ICON /////////////////
    ///////////////// ICON /////////////////

    .mat-step-icon {
      background-color: var(--color-third);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background-color: var(--color-primary);
        border-radius: 50%;
        transform: translate(-50%, 30px);
        opacity: 0;
        transition: ease-in-out 0.35s;
      }
    }

    .mat-step-icon-state-number {
      font-family: var(--font-title);
    }

    .mat-step-icon-selected,
    .mat-step-icon-state-edit {
      background-color: var(--color-primary);
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--font-size-label);
    }

    &:hover {
      .mat-step-icon-selected,
      .mat-step-icon-state-edit {
        background-color: var(--color-primary-lighten);
        &:before {
          transform: translate(-50%, 15px);
          opacity: 1;
          transition: ease-in-out 0.35s;
        }
      }
    }
    .mat-step-icon-selected {
      &:before {
        transform: translate(-50%, 15px);
        opacity: 1;
        transition: ease-in-out 0.35s;
      }
    }
  }
}
