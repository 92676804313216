.filter-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  form {
    display: flex;
    justify-content: flex-end;
    width: 50%;
    margin-left: auto;
    padding: 0;
    gap: 10px;

    mat-form-field {
      padding: 0;
    }
  }
}

.full-filter-bar {
  display: inline-flex;
  width: 100%;
  gap: 20px;

  .action-col {
    display: flex;
    flex: 1;
    gap: 10px;

    mat-form-field {
      width: 70%;
    }
    button {
      width: 30%;
    }
  }
}

.form-actions-horizontal {
  display: flex;
  gap: 10px;
  flex-direction: row;
}
.form-actions-vertical {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
