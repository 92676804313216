.cdk-overlay-pane {
  &.fullscreen {
    max-width: unset !important;
    width: 100%;
    height: 100%;
    .mat-dialog-container {
      max-width: 100vw;
      max-height: 100vh;
      height: 100%;
      width: 100%;
      .mat-dialog-content {
        max-height: 80vh;
      }
    }
  }
}
