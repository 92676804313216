@mixin toastr($color: --color-primary) {
  font-family: var(--font-title) serif;
  color: var($color);
  border-color: var($color);
  opacity: 0.5;
  background-image: none;
  overflow: hidden;
  padding: 10px 10px 10px 70px !important;

  .toast-title {
    font-weight: var(--font-weight-bold);
  }
}
.toast-success {
  background-color: #b3e1f1;
  @include toastr(--color-primary);
}
.toast-info {
  background-color: #c3cace;
  @include toastr(--color-third);
}
.toast-warning {
  background-color: #f9d2b6;
  @include toastr(--color-secondary);
}
.toast-error {
  background-color: #fbbdbb;
  @include toastr(--color-danger);
}
