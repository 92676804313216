@mixin badge() {
  display: inline-flex;
  height: var(--size-mini);
  color: var(--color-white);
  border-radius: 4px;
  padding: 0 10px;
  font-family: var(--font-title);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-text);
}

.badge {
  &-primary {
    @include badge();
    background-color: var(--color-primary);
  }
  &-accent {
    @include badge();
    background-color: var(--color-secondary);
  }
  &-third {
    @include badge();
    background-color: var(--color-third);
  }
  &-status-libre {
    @include badge();
    background-color: var(--color-success);
  }
  &-status-option {
    @include badge();
    background-color: var(--color-secondary);
  }
  &-status-vnr {
    @include badge();
    background-color: var(--color-yellow);
  }
  &-status-reserve {
    @include badge();
    background-color: var(--color-danger);
  }
  &-dispositif-psla {
    @include badge();
    background-color: var(--color-psla);
  }
  &-dispositif-apm {
    @include badge();
    background-color: var(--color-apm);
  }
  &-dispositif-libre{
    @include badge();
    background-color: var(--color-libre);
    &.pinel {
      background-color: var(--color-pinelplus);
    }
  }
  &-dispositif-lli {
    @include badge();
    background-color: var(--color-lli);
  }
  &-dispositif-tertiaire {
    @include badge();
    background-color: var(--color-tertiaire);
  }
  &-dispositif-accession {
    @include badge();
    background-color: var(--color-accession);
  }
  &-dispositif-interne {
    @include badge();
    background-color: var(--color-interne);
  }
  &-dispositif-gere {
    @include badge();
    background-color: var(--color-gere);
  }
  &-dispositif-rehab {
    @include badge();
    background-color: var(--color-rehab);
  }
  &-dispositif-lmnp {
    @include badge();
    background-color: var(--color-lmnp);
  }
  &-dispositif-nue {
    @include badge();
    background-color: var(--color-nue);
  }
  &-dispositif-tab {
    @include badge();
    background-color: var(--color-tab);
  }
  &-dispositif-brs {
    @include badge();
    background-color: var(--color-brs);
  }
  &-dispositif-malraux {
    @include badge();
    background-color: var(--color-malraux);
  }
  &-dispotif-foncier {
    @include badge();
    background-color: var(--color-foncier);
  }
  &-dispositif-mh {
    @include badge();
    background-color: var(--color-mh);
  }
  &-dispositif-tva {
    @include badge();
    background-color: var(--color-anru_npnru);
  }
}
