.mat-tooltip {
  background-color: var(--color-third);
  &.tooltip-primary {
    background-color: var(--color-primary);
  }
  &.tooltip-accent {
    background-color: var(--color-secondary);
  }
  &.tooltip-third {
    background-color: var(--color-third);
  }
  &.tooltip-warn {
    background-color: var(--color-danger);
  }
}
