// *** RESPONSIVE BREAKPOINTS *** //
///////////////////////////////////

@mixin breakpoint($point, $down: false) {
  @if $point == xxl {
    @media all and (min-width: 1820px) {
      @content;
    }
  } @else if $point == xl {
    @media all and (min-width: 1440px) {
      @content;
    }
  } @else if $point == lg {
    @media all and (min-width: 992px) {
      @content;
    }
  } @else if $point == md {
    @if $down {
      @media all and (max-width: 767px) {
        @content;
      }
    } @else {
      @media all and (min-width: 768px) {
        @content;
      }
    }
  } @else if $point == sm {
    @if $down {
      @media all and (max-width: 567px) {
        @content;
      }
    } @else {
      @media all and (min-width: 568px) {
        @content;
      }
    }
  } @else if $point == xs {
    @media all and (min-width: 375px) {
      @content;
    }
  } @else if $point == xxs {
    @media all and (min-width: 320px) {
      @content;
    }
  } @else if $point == mobile-max {
    @media all and (max-width: 767px) and (orientation: portrait) {
      @content;
    }
  }

  // landscape
  @else if $point == lg-landscape {
    @media all and (min-height: 1024px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == md-landscape {
    @media all and (min-height: 768px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == sm-landscape {
    @media all and (min-height: 568px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == xs-landscape {
    @media all and (min-height: 375px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == xxs-landscape {
    @media all and (max-height: 374px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == mobile-max-landscape {
    @media all and (max-width: 767px) and (orientation: landscape) {
      @content;
    }
  }
}

/*$$$$$$$  /$$$$$$$$  /$$$$$$  /$$$$$$$   /$$$$$$  /$$   /$$  /$$$$$$  /$$$$$$ /$$    /$$ /$$$$$$$$
  | $$__  $$| $$_____/ /$$__  $$| $$__  $$ /$$__  $$| $$$ | $$ /$$__  $$|_  $$_/| $$   | $$| $$_____/
  | $$  \ $$| $$      | $$  \__/| $$  \ $$| $$  \ $$| $$$$| $$| $$  \__/  | $$  | $$   | $$| $$
  | $$$$$$$/| $$$$$   |  $$$$$$ | $$$$$$$/| $$  | $$| $$ $$ $$|  $$$$$$   | $$  |  $$ / $$/| $$$$$
  | $$__  $$| $$__/    \____  $$| $$____/ | $$  | $$| $$  $$$$ \____  $$  | $$   \  $$ $$/ | $$__/
  | $$  \ $$| $$       /$$  \ $$| $$      | $$  | $$| $$\  $$$ /$$  \ $$  | $$    \  $$$/  | $$
  | $$  | $$| $$$$$$$$|  $$$$$$/| $$      |  $$$$$$/| $$ \  $$|  $$$$$$/ /$$$$$$   \  $/   | $$$$$$$$
  |__/  |__/|________/ \______/ |__/       \______/ |__/  \__/ \______/ |______/    \_/    |_______*/

//@include breakpoint(xxs) {}
//@include breakpoint(xs) {}
//@include breakpoint(sm) {}
//@include breakpoint(md) {}
//@include breakpoint(lg) {}
//@include breakpoint(xl) {}
//@include breakpoint(xxl) {}
//@include breakpoint(xxs-landscape) {}
//@include breakpoint(xs-landscape) {}
//@include breakpoint(sm-landscape) {}
//@include breakpoint(md-landscape) {}
//@include breakpoint(lg-landscape) {}
