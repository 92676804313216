:root {
  // Primary colors
  --color-primary-50: #e0f3f9;
  --color-primary-100: #b3e1f1;
  --color-primary-200: #80cee8;
  --color-primary-300: #4dbade;
  --color-primary-400: #26abd7;
  --color-primary-500: #009cd0;
  --color-primary-600: #0094cb;
  --color-primary-700: #008ac4;
  --color-primary-800: #0080be;
  --color-primary-900: #006eb3;
  --color-primary-A100: #dcf0ff;
  --color-primary-A200: #a9d9ff;
  --color-primary-A400: #76c3ff;
  --color-primary-A700: #5db8ff;
  --color-primary-contrast-50: #000000;
  --color-primary-contrast-100: #000000;
  --color-primary-contrast-200: #000000;
  --color-primary-contrast-300: #000000;
  --color-primary-contrast-400: #000000;
  --color-primary-contrast-500: #ffffff;
  --color-primary-contrast-600: #ffffff;
  --color-primary-contrast-700: #ffffff;
  --color-primary-contrast-800: #ffffff;
  --color-primary-contrast-900: #ffffff;
  --color-primary-contrast-A100: #000000;
  --color-primary-contrast-A200: #000000;
  --color-primary-contrast-A400: #000000;
  --color-primary-contrast-A700: #000000;

  // Secondary colors
  --color-secondary-50: #fdede2;
  --color-secondary-100: #f9d2b6;
  --color-secondary-200: #f5b485;
  --color-secondary-300: #f19654;
  --color-secondary-400: #ee8030;
  --color-secondary-500: #eb690b;
  --color-secondary-600: #e9610a;
  --color-secondary-700: #e55608;
  --color-secondary-800: #e24c06;
  --color-secondary-900: #dd3b03;
  --color-secondary-A100: #ffffff;
  --color-secondary-A200: #ffdad1;
  --color-secondary-A400: #ffb19e;
  --color-secondary-A700: #ff9d85;
  --color-secondary-contrast-50: #000000;
  --color-secondary-contrast-100: #000000;
  --color-secondary-contrast-200: #000000;
  --color-secondary-contrast-300: #000000;
  --color-secondary-contrast-400: #000000;
  --color-secondary-contrast-500: #ffffff;
  --color-secondary-contrast-600: #ffffff;
  --color-secondary-contrast-700: #ffffff;
  --color-secondary-contrast-800: #ffffff;
  --color-secondary-contrast-900: #ffffff;
  --color-secondary-contrast-A100: #000000;
  --color-secondary-contrast-A200: #000000;
  --color-secondary-contrast-A400: #000000;
  --color-secondary-contrast-A700: #000000;

  // Third colors
  --color-third-50: #e7eaeb;
  --color-third-100: #c3cace;
  --color-third-200: #9ba6ae;
  --color-third-300: #73828d;
  --color-third-400: #556874;
  --color-third-500: #374d5c;
  --color-third-600: #314654;
  --color-third-700: #2a3d4a;
  --color-third-800: #233441;
  --color-third-900: #162530;
  --color-third-A100: #72bfff;
  --color-third-A200: #3fa8ff;
  --color-third-A400: #0c90ff;
  --color-third-A700: #0084f1;
  --color-third-contrast-50: #000000;
  --color-third-contrast-100: #000000;
  --color-third-contrast-200: #000000;
  --color-third-contrast-300: #000000;
  --color-third-contrast-400: #000000;
  --color-third-contrast-500: #ffffff;
  --color-third-contrast-600: #ffffff;
  --color-third-contrast-700: #ffffff;
  --color-third-contrast-800: #ffffff;
  --color-third-contrast-900: #ffffff;
  --color-third-contrast-A100: #000000;
  --color-third-contrast-A200: #000000;
  --color-third-contrast-A400: #ffffff;
  --color-third-contrast-A700: #ffffff;

  // Other colors
  --color-primary: var(--color-primary-500);
  --color-primary-lighten: var(--color-primary-300);
  --color-secondary: var(--color-secondary-500);
  --color-secondary-lighten: var(--color-secondary-300);
  --color-white: #ffffff;
  --color-grey: #f3f3f3;
  --color-grey-darken: #c7d2db;
  --color-black: #252525;
  --color-yellow: #d9b40f;
  --color-danger: #f3231c;
  --color-danger-lighten: #f54c46;
  --color-success: #4fc553;
  --color-third: #374d5c;
  --color-third-lighten: #577b94;
  --color-third-darken: #2b3d4a;

  --color-libre: #002D53  ;
  --color-psla: #6FC671;
  --color-apm: #4C0066;
  --color-tertiaire: #c92828;
  --color-accession: #009697;
  --color-interne: #AF929D;
  --color-gere: #9984D4;
  --color-rehab: #e0e011;
  --color-lmnp: #eb690b;
  --color-nue: #22A2F2;
  --color-tab: #18D9B5;
  --color-brs: #0D6EFD;
  --color-malraux: #495B73;
  --color-foncier: #36403A;
  --color-mh: #177373;
  --color-pinelplus: #F2B705;
  --color-anru_npnru: #DE5328;
  --color-lli: #F2B705;

  --color-option-status-green: var(--color-success);
  --color-option-status-orange: #f58c46;
  --color-option-status-red: var(--color-danger);
}

// http://mcg.mbitson.com/#!?mcgpalette0=%23eb9844
$mat-custom-primary: (
  50: var(--color-primary-50),
  100: var(--color-primary-100),
  200: var(--color-primary-200),
  300: var(--color-primary-300),
  400: var(--color-primary-400),
  500: var(--color-primary-500),
  600: var(--color-primary-600),
  700: var(--color-primary-700),
  800: var(--color-primary-800),
  900: var(--color-primary-900),
  A100: var(--color-primary-A100),
  A200: var(--color-primary-A200),
  A400: var(--color-primary-A400),
  A700: var(--color-primary-A700),
  contrast: (
    50: var(--color-primary-contrast-50),
    100: var(--color-primary-contrast-100),
    200: var(--color-primary-contrast-200),
    300: var(--color-primary-contrast-300),
    400: var(--color-primary-contrast-400),
    500: var(--color-primary-contrast-500),
    600: var(--color-primary-contrast-600),
    700: var(--color-primary-contrast-700),
    800: var(--color-primary-contrast-800),
    900: var(--color-primary-contrast-900),
    A100: var(--color-primary-contrast-A100),
    A200: var(--color-primary-contrast-A200),
    A400: var(--color-primary-contrast-A400),
    A700: var(--color-primary-contrast-A700),
  ),
);

$mat-custom-accent: (
  50: var(--color-secondary-50),
  100: var(--color-secondary-100),
  200: var(--color-secondary-200),
  300: var(--color-secondary-300),
  400: var(--color-secondary-400),
  500: var(--color-secondary-500),
  600: var(--color-secondary-600),
  700: var(--color-secondary-700),
  800: var(--color-secondary-800),
  900: var(--color-secondary-900),
  A100: var(--color-secondary-A100),
  A200: var(--color-secondary-A200),
  A400: var(--color-secondary-A400),
  A700: var(--color-secondary-A700),
  contrast: (
    50: var(--color-secondary-contrast-50),
    100: var(--color-secondary-contrast-100),
    200: var(--color-secondary-contrast-200),
    300: var(--color-secondary-contrast-300),
    400: var(--color-secondary-contrast-400),
    500: var(--color-secondary-contrast-500),
    600: var(--color-secondary-contrast-600),
    700: var(--color-secondary-contrast-700),
    800: var(--color-secondary-contrast-800),
    900: var(--color-secondary-contrast-900),
    A100: var(--color-secondary-contrast-A100),
    A200: var(--color-secondary-contrast-A200),
    A400: var(--color-secondary-contrast-A400),
    A700: var(--color-secondary-contrast-A700),
  ),
);

$mat-custom-third: (
  50: var(--color-third-50),
  100: var(--color-third-100),
  200: var(--color-third-200),
  300: var(--color-third-300),
  400: var(--color-third-400),
  500: var(--color-third-500),
  600: var(--color-third-600),
  700: var(--color-third-700),
  800: var(--color-third-800),
  900: var(--color-third-900),
  A100: var(--color-third-A100),
  A200: var(--color-third-A200),
  A400: var(--color-third-A400),
  A700: var(--color-third-A700),
  contrast: (
    50: var(--color-third-contrast-50),
    100: var(--color-third-contrast-100),
    200: var(--color-third-contrast-200),
    300: var(--color-third-contrast-300),
    400: var(--color-third-contrast-400),
    500: var(--color-third-contrast-500),
    600: var(--color-third-contrast-600),
    700: var(--color-third-contrast-700),
    800: var(--color-third-contrast-800),
    900: var(--color-third-contrast-900),
    A100: var(--color-third-contrast-A100),
    A200: var(--color-third-contrast-A200),
    A400: var(--color-third-contrast-A400),
    A700: var(--color-third-contrast-A700),
  ),
);
