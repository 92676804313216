@import "mixins";

:root {
  --font-classic: "Open Sans", sans-serif;
  --font-title: "Oswald", sans-serif;

  --font-size-tall: 28px;
  --font-size-medium: 20px;
  --font-size-regular: 16px;
  --font-size-text: 12px;
  --font-size-label: 10px;
  --font-size-small: 10px;

  @include breakpoint(lg) {
    --font-size-tall: 35px;
    --font-size-medium: 25px;
    --font-size-regular: 20px;
    --font-size-text: 15px;
    --font-size-label: 12px;
    --font-size-small: 10px;
  }

  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-title), serif;
  margin: 0;
  color: var(--color-third);
}

h1 {
  font-size: var(--font-size-tall);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}

h2 {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

h3 {
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

h4 {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

h5,
h6 {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-medium);
  line-height: 1;
}

h6 {
  font-weight: var(--font-weight-regular);
}

p {
  font-family: var(--font-classic), serif;
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.detail-text {
  font-size: var(--font-size-small);
  color: var(--color-primary);
}

.text-bold {
  font-weight: bold;
}

.important-third {
  font-weight: bold;
  color: var(--color-third);
}

.text-sm {
  font-size: var(--font-size-small);
}

.text-label {
  font-size: var(--font-size-label);
}

.text-error {
  color: var(--color-danger);
}

.text-white {
  color: var(--color-white);
}

.text-nl2br {
  white-space: pre-line;
}

.link {
  color: var(--color-primary);
  transition: ease-in-out 0.3s;
  text-decoration: underline;

  &:hover {
    color: var(--color-third);
    transition: ease-in-out 0.3s;
  }
}

