///////////////// INPUT DEFAULT /////////////////
///////////////// INPUT DEFAULT /////////////////

.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field div.mat-mdc-form-field-wrapper {
  min-height: var(--size-tall);
  padding: 0;
  margin: 0;
}

.mat-mdc-form-field-underline {
  bottom: 0;
}

.mat-mdc-form-field-subscript-wrapper {
  top: calc(100% - 0.5em);

}

mat-mdc-slide-toggle {
  label.mat-slide-toggle-label {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.mat-form-field-appearance {
  margin-bottom: var(--padding-small);
}

///////////////// INPUT OUTLINE /////////////////
///////////////// INPUT OUTLINE /////////////////

.mat-form-field-appearance-outline {
  mat-label {
    font-family: var(--font-title);
    color: var(--color-third-lighten);
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex .mat-form-field-infix {
      padding: 0.7em 0 0.7em 0;
    }
  }

  .mat-form-field-outline {
    height: 50px;
  }

  .mat-form-field-infix {
    border-top: 0.55em solid transparent;


  }

  .mat-form-field-flex:hover .mat-form-field-outline {
    opacity: 0;
    transition: none !important;
  }

  ///////////////// HOVER /////////////////
  ///////////////// HOVER /////////////////

  .mat-select-arrow {
    color: var(--color-primary);
  }

  .mat-form-field-flex:hover {
    .mat-form-field-outline div {
      border-color: var(--color-primary);
    }

    .icon-home {
      color: var(--color-primary);
    }
  }

  ///////////////// ICON /////////////////
  ///////////////// ICON /////////////////

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.1em;
  }

  div.mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}

.mat-mdc-form-field-icon-suffix i {
  padding-right: 0.5em;
}

.input-theme-dark {
  mat-hint {
    color: var(--color-white);
  }

  .mat-form-field-flex {
    &:hover {
      .mat-form-field-outline {
        opacity: 1;
      }
    }
  }

  .mdc-text-field--outlined {
    background-color: white;
    border-radius: 5px;
  }
}

/* .cdk-overlay-pane {
  transform: translateX(-12px) translateY(30px);
} */
