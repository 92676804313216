// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "palettes";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sporting-app-primary: mat.define-palette($mat-custom-primary);
$sporting-app-accent: mat.define-palette($mat-custom-accent, 500, 200, 900);

// The warn palette is optional (defaults to red).
$sporting-app-warn: mat.define-palette(mat.$blue-grey-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sporting-app-theme: mat.define-light-theme($sporting-app-primary, $sporting-app-accent, $sporting-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sporting-app-theme);

.cdk-overlay-container {
  z-index: 2000;
}
