@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon/icomoon.eot?iw4bs1");
  src: url("/assets/fonts/icomoon/icomoon.eot?iw4bs1#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon/icomoon.woff2?iw4bs1") format("woff2"),
    url("/assets/fonts/icomoon/icomoon.ttf?iw4bs1") format("truetype"),
    url("/assets/fonts/icomoon/icomoon.woff?iw4bs1") format("woff"),
    url("/assets/fonts/icomoon/icomoon.svg?iw4bs1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e935";
}
.icon-video:before {
  content: "\e936";
}
.icon-close:before {
  content: "\e932";
}
.icon-arrow-up:before {
  content: "\e933";
}
.icon-arrow-down:before {
  content: "\e934";
}
.icon-target:before {
  content: "\e92e";
}
.icon-info:before {
  content: "\e92f";
}
.icon-error:before {
  content: "\e930";
}
.icon-warn:before {
  content: "\e931";
}
.icon-arrow-left:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e92d";
}
.icon-fav-stroke:before {
  content: "\e917";
}
.icon-logout:before {
  content: "\e92c";
}
.icon-refresh:before {
  content: "\e924";
}
.icon-mail:before {
  content: "\e92b";
}
.icon-add-prospect:before {
  content: "\e900";
}
.icon-admin:before {
  content: "\e901";
}
.icon-agent:before {
  content: "\e902";
}
.icon-back:before {
  content: "\e904";
}
.icon-build:before {
  content: "\e905";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-checkbox:before {
  content: "\e907";
}
.icon-clock:before {
  content: "\e908";
}
.icon-coin:before {
  content: "\e909";
}
.icon-company:before {
  content: "\e90a";
}
.icon-correct:before {
  content: "\e90b";
}
.icon-delete:before {
  content: "\e90c";
}
.icon-document:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e90e";
}
.icon-folder:before {
  content: "\e90f";
}
.icon-gestclient:before {
  content: "\e910";
}
.icon-gps:before {
  content: "\e911";
}
.icon-group:before {
  content: "\e912";
}
.icon-fav-full:before {
  content: "\e913";
}
.icon-historique:before {
  content: "\e914";
}
.icon-home:before {
  content: "\e915";
}
.icon-key:before {
  content: "\e916";
}
.icon-new_actu:before {
  content: "\e918";
}
.icon-news:before {
  content: "\e919";
}
.icon-option:before {
  content: "\e91a";
}
.icon-partner:before {
  content: "\e91b";
}
.icon-pay:before {
  content: "\e91c";
}
.icon-pen:before {
  content: "\e91d";
}
.icon-phone:before {
  content: "\e91e";
}
.icon-photo:before {
  content: "\e91f";
}
.icon-pin:before {
  content: "\e920";
}
.icon-plus:before {
  content: "\e921";
}
.icon-prospect:before {
  content: "\e922";
}
.icon-question:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e925";
}
.icon-spy:before {
  content: "\e926";
}
.icon-star:before {
  content: "\e927";
}
.icon-boost:before {
  content: "\e928";
}
.icon-user:before {
  content: "\e929";
}
.icon-website:before {
  content: "\e92a";
}
