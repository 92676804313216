mat-dialog-container .mat-mdc-dialog-surface {
  padding: 50px;
}

.modal-transparent {
  pointer-events: none !important;
  .mat-mdc-dialog-container {
    padding: 0;
    background: transparent;
    box-shadow: none;
    overflow: hidden;
  }
}

.modal-medium {
  width: 60vw;
}

.modal-favorites {
  .mat-dialog-title {
    text-align: center;
  }
  .mat-dialog-content {
    height: 40vh;
    width: 50vw;
  }
}
