.mat-mdc-table {
  background-color: transparent !important;
  width: 100%;
  td,
  th {
    height: 50px;
    padding: 0;
    &.mat-cell:first-of-type,
    &.mat-cell:last-of-type,
    &.mat-header-cell:first-of-type,
    &.mat-header-cell:last-of-type {
      padding: 0;
    }
  }

  th .mat-sort-header-container {
    justify-content: center;
    .mat-sort-header-content {
      padding-left: 10px;
    }
  }

  //////////// FONT HEADER ////////////
  .mat-mdc-header-cell {
    font-family: var(--font-title), serif;
    font-size: var(--font-size-text);
    color: var(--color-third);
    text-align: center;
  }

  //////////// bordure / couleur alternée des row ////////////

  tbody tr {
    td.mat-mdc-cell {
      border-bottom: 1px solid var(--color-grey-darken);
      text-align: center;
    }
    &:nth-of-type(odd) td {
      background-color: white;
    }
    &:nth-of-type(even) td {
      background-color: var(--color-grey);
    }
    &:last-of-type {
      td {
        border-bottom: none !important;
      }
    }
  }
  thead tr {
    th {
      border-bottom: none !important;
    }
  }

  //////////// buttons in column ////////////
  .mat-mdc-cell {
    &.mat-column-actions {
      text-align: end;
      button,
      a {
        margin: 0 5px;
      }
    }
  }

  //////////// Couleur des textes ////////////

  .text-primary {
    color: var(--color-primary);
  }
  .text-accent {
    color: var(--color-accent);
  }
  .text-warn {
    color: var(--color-danger);
  }
  .text-success {
    color: var(--color-success);
  }
}

.results-empty {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-third);
  font-weight: bold;
}
