/////////// Padding - Margin Global ////////////

.pt-mini {
  padding-top: var(--padding-mini);
}
.pt-small {
  padding-top: var(--padding-small);
}
.pt-regular {
  padding-top: var(--padding-regular);
}
.pt-medium {
  padding-top: var(--padding-medium);
}
.pb-mini {
  padding-bottom: var(--padding-mini);
}
.pb-small {
  padding-bottom: var(--padding-small);
}
.pb-regular {
  padding-bottom: var(--padding-regular);
}
.pb-medium {
  padding-bottom: var(--padding-medium);
}
.py-regular {
  padding: var(--padding-regular) 0;
}
.py-small {
  padding: var(--padding-small) 0;
}
.py-mini {
  padding: var(--padding-mini) 0;
}

.mr-small {
  margin-right: var(--margin-small);
}

///////////////////////////////////////////////
.leaflet-popup-content { width: auto !important; }
.popup-container {
  display: flex;
  white-space: nowrap;

  .popup-link {
    cursor: pointer;
  }

  .popup-image {
    width: auto;
    height: 80px;
  }
}

.is-block {
  position: relative;
  padding: var(--padding-regular) 0;
  &:nth-of-type(even),
  &:nth-child(even) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 100vw;
      background: var(--color-grey);
      z-index: -1;
    }
  }
}

////////////// SCROLLBAR //////////////
////////////// SCROLLBAR //////////////

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  border: none;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
  //border: 2px solid #fff;
}
