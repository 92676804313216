// Settings custom
@use "styles/settings/mixins";
@use "styles/settings/palettes";
@use "styles/settings/materials";
@use "styles/settings/font";
@use "styles/settings/icomoon";
@use "styles/settings/variables";
@use "styles/settings/global";

// Components custom
@use "styles/components/badges";
@use "styles/components/buttons";
@use "styles/components/checkboxes";
@use "styles/components/chips";
@use "styles/components/filterbar";
@use "styles/components/inputs";
@use "styles/components/modal";
@use "styles/components/modals";
@use "styles/components/radios";
@use "styles/components/stepper";
@use "styles/components/tables";
@use "styles/components/toastr";
@use "styles/components/tooltips";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Oswald:wght@300;400;500;700&display=swap");

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/quill/dist/quill.snow.css";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";

// Materials
@import "@angular/cdk/overlay-prebuilt.css";

// Toaster - Boostrap 4 version
@import "ngx-toastr/toastr-bs4-alert";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //overflow-x: hidden;
}

body {
  overflow-x: hidden;

  .page-container-relative {
    position: relative;
    width: 100%;
  }

  app-layout-logged {
    .page-container {
      @media (min-width: 576px) {
        min-height: calc(100vh - var(--height-header) - var(--height-footer) - var(--height-footer-bottom));
      }
      min-height: calc(150vh - var(--height-header) - var(--height-footer) - var(--height-footer-bottom));

      &.footer-small {
        min-height: calc(100vh - var(--height-header) - var(--height-footer-bottom));
      }
    }
  }

  &.sp-subheader-show {
    app-layout-logged {
      .page-container {
        min-height: calc(
                100vh - var(--height-header) - var(--height-subheader) - var(--height-footer) - var(--height-footer-bottom)
        );

        &.footer-small {
          min-height: calc(100vh - var(--height-header) - var(--height-subheader) - var(--height-footer-bottom));
        }
      }
    }
  }
}

.sp-theme-accent {
  .history-back {
    background-color: var(--color-secondary);
  }
}
