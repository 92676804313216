.radio-theme-dark {
  label {
    color: var(--color-white);
  }
}

div.mat-radio-container,
div.mat-radio-outer-circle,
div.mat-radio-inner-circle {
  width: var(--size-checkbox);
  height: var(--size-checkbox);
}

div.mat-radio-outer-circle {
  background-color: white;
  border-color: var(--color-grey-darken);
  border-width: 1px;
  border-style: solid;
}

.mat-radio-label {
  font-size: var(--font-size-label);
  margin-right: 0.5rem;
}
