mat-checkbox {
  &.checkbox-theme-dark {
    label {
      color: var(--color-white);
    }
    .mat-mdc-checkbox-frame {
      border-color: var(--color-white);
    }
  }
}

.mdc-label {
  margin-bottom: 0;
  padding: 0;
  font-size: var(--font-size-label);

}

div.mat-checkbox-inner-container {
  width: var(--size-checkbox);
  height: var(--size-checkbox);
}

div.mdc-checkbox__background {
  background-color: white;
  border-color: var(--color-grey-darken) !important;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
}

